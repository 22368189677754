<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Proteção solar, decoração e privacidade</h2>

            <h3>SunTek UltravisionDS</h3>

            <p>É a ultima geração de películas espectralmente seletiva. A aparência, clareza óptica e qualidade são excelentes. É um produto destinado aos clientes mais exigentes que não abrem mão da claridade e visibilidade, seja no escritório ou na residência.</p>

            <h4>Especificações - ULVDS 70</h4>

            <ul class="list">
              <li>A transmissão da luz visível de 67%</li>
              <li>Reflexão da luz visível • Interior de 8% • Exterior 9%</li>
              <li>Rejeição UV 99%</li>
              <li>Redução do brilho: 24%</li>
              <li>Coeficiente de Sombreamento 0,69</li>
              <li>IR Rejeitado @ 980 nm 70%</li>
              <li>Energia Solar Total Rejeitado 40%</li>
            </ul>

            <h4>Especificações - ULVDS 40</h4>

            <ul class="list">
              <li>A transmissão da luz visível de 38%</li>
              <li>Reflexão da luz visível • Interior de 14% • Exterior 17%</li>
              <li>Rejeição UV 99%</li>
              <li>Redução do brilho: 57%</li>
              <li>Coeficiente de Sombreamento 0,46</li>
              <li>Ganho de Calor Solar Coeficiente 0,40</li>
              <li>IR Rejeitado @ 980 nm 85%</li>
              <li>Energia Solar Total Rejeitado 60%</li>
            </ul>

            <h4>Benefícios</h4>

            <p>
              Alta performance solar, redução de calor, redução dos raios infravermelho e ultravioleta. Elegante, com aparência neutra. Superior clareza óptica e redução de brilho. Baixa refletividade interna e externa, garantindo
              uma visão inalterada.
            </p>

            <h4>Proteção Contra Desbotamento</h4>
            <p>Ultra-VisionDS ajuda a reduzir o desbotamento, bloqueando 99% dos raios ultravioleta. Isso ajuda a proteger objetos valiosos, incluindo as mercadorias de lojas, móveis e pisos. Além disso, reduz o calor em até 60% sem alterar a visibilidade.</p>

            <h4>Economia</h4>
            <p>Excesso de calor solar que passa através de suas janelas pode responder por uma parcela significativa nos custos com ar condicionado. UltraVisionDS ajuda na economia de energia, reduzindo o ganho de calor e aumenta o conforto em sua casa ou escritório.</p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

export default {

  mixins: [layoutInternalCreated],

  components: {
    QuotationCall,
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Películas SunTek");
    this.$emit(
      `update:introduction`,
      "Instalamos as películas SunTek em comércios, sacadas, escritórios, apartamentos, divisórias, portarias e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

